import React, { useState, useEffect, useContext } from 'react';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';
import FeatureToast from '../../Components/FeatureWrapper/Toast';
import FormControl from '@mui/material/FormControl';
import { InputLabel, Modal } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { searchSelector } from '../../reducers/searchSlice';
import FeaturedVehicles from '../../Components/FeatureWrapper/FeaturedVehicles';
import { get_system_configurations } from '../../API/system_configurations';
import { AuthContext } from '../../contexts/AuthContext';
import './index.css';
import ChooseVehicle from '../../Components/FeatureWrapper/ChooseVehicle';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { add, format } from 'date-fns';
import { useCallback } from 'react';
import { getCarUnavailablity } from '../../helpers/unavailablity';
import { get_all_cars } from '../../API/index';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@material-ui/core';

export default function FeaturedPage({
  ...props
  // fromDate,
  // untilDate,
  // airportName,
  // isDifferentDropoff,
  // dropoffAirportName,
  // fromTime,
  // setFromDate,
  // setUntilDate,
  // setAirportName,
  // setIsDifferentDropoff
  // setDropoffAirportName
  // setFromTime,
  // setUntilTime,
  // untilTime,
  // bookingPeriod,
}) {
  const navigate = useNavigate();
  const [vehicle_type, setVehicle_type] = useState(null);
  const [no_of_passengers, setNo_of_passengers] = useState(null);
  const [fuelType, setFuelType] = useState(null);
  const [car_Color, setCar_Color] = useState([]);
  const [driveTrain, setDriveTrain] = useState([]);
  const [size, setSize] = useState([]);
  const [sortBy, setSortBy] = useState('daily_price');
  const { q } = useSelector(searchSelector);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCars, setAllCars] = useState(null);

  const [systemConfigurations, setSystemConfigurations] = useState(null);
  const { user, authAxios } = useContext(AuthContext);

  useEffect(() => {
    get_system_configurations(authAxios).then((res) => {
      setSystemConfigurations(res.data[0]);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.dropoffAirportName || props.dropoffAirportName === '') {
      navigate('/');
    }
  }, []);

  function sanitize(html) {
    var doc = document.createElement('div');
    doc.innerHTML = html;
    return doc.innerHTML;
  }

  const format_date = (date, time) => {
    return date && format(date, 'yyyy-MM-dd') + 'T' + time;
  };

  const getFilterVars = useCallback(() => {
    const start_date = format_date(props.fromDate, props.fromTime);
    const end_date = format_date(props.untilDate, props.untilTime);
    let vars = { title: q };
    if (vehicle_type != 'all') vars.type = vehicle_type;
    if (no_of_passengers != 'all') vars.number_of_bags = no_of_passengers;
    if (!car_Color.includes('all')) vars.vechicle_color_array = car_Color;
    if (!driveTrain.includes('all')) vars.drive_train_array = driveTrain;
    if (!size.includes('all')) vars.number_of_seats_array = size;
    if (fuelType != 'all') vars.fuel_type = fuelType;
    if (sortBy) vars.order_name = sortBy;
    vars.start_date = start_date;
    vars.end_date = end_date;
    return vars;
  }, [
    props.fromDate,
    props.fromTime,
    props.untilDate,
    props.untilTime,
    q,
    vehicle_type,
    no_of_passengers,
    car_Color,
    driveTrain,
    size,
    fuelType,
    sortBy,
  ]);

  useEffect(() => {
    getAllCars();
  }, [getFilterVars]);

  const getAllCars = (page, prev_data) => {
    // setIsFetching(true);
    // setAllCars({ data: [] });
    get_all_cars(getFilterVars(), null, page).then((res) => {
      setIsFetching(false);
      if (res.status === 200 || res.status === 304) {
        // setAllCars(res.data);
        if (prev_data) {
          setAllCars({
            pagination: {
              count: res?.data?.count,
              total_pages: res?.data?.total_pages,
              page_number: res?.data?.page_number,
            },
            data: [
              ...prev_data,
              ...res.data?.results
                .map((car) => ({
                  unavailable: car.available_status !== "available",
                  ...car,
                }))
                .filter((car) => {
                  if (
                    getFilterVars().drive_train_array.includes(car.drive_train)
                  ) {
                    return true;
                  }
                  if (
                    getFilterVars().drive_train_array.includes(
                      "All-Wheel Drive"
                    ) &&
                    !car.drive_train
                  ) {
                    return true;
                  }
                  if (getFilterVars().drive_train_array.length == 0)
                    return true;
                  return false;
                }),
            ],
          });
        } else {
          setAllCars({
            pagination: {
              count: res?.data?.count,
              total_pages: res?.data?.total_pages,
              page_number: res?.data?.page_number,
            },
            data: res.data?.results
              .map((car) => ({
                unavailable: car.available_status !== "available",
                ...car,
              }))
              .filter((car) => {
                if (
                  getFilterVars().drive_train_array.includes(car.drive_train)
                ) {
                  return true;
                }
                if (
                  getFilterVars().drive_train_array.includes(
                    "All-Wheel Drive"
                  ) &&
                  !car.drive_train
                ) {
                  return true;
                }
                if (getFilterVars().drive_train_array.length == 0) return true;
                return false;
              }),
          });
        }
      } else {
        setAllCars([]);
      }
    });
  };

  const vehicleSize = [
    // 'All',
    'Exotic',
    'Convertible',
    'Specialty',
    'Small SUV',
    'Midsize SUV',
    'Large SUV',
    'Small Sedan',
    'Large Sedan',
    'Truck',
    'Van',
  ];

  const _colors = [
    // 'All',
    'Black',
    'Gray',
    'White',
    'Red',
    'Blue',
    'Yellow',
    'Orange',
    'Brown',
    'Green',
    'Purple',
  ];

  const _driveTrains = [
    // 'All',
    '4WD/AWD',
    'Front-Wheel Drive',
    'Rear-Wheel Drive',
  ];

  return (
    <div>
      <Navbar search={true} />
      <div className='featuredPage'>
        <FeatureToast step={3} props={props} />
        <div className='booking_note'>
          {systemConfigurations &&
          systemConfigurations.days_without_discount &&
          systemConfigurations?.discount_percentage &&
          systemConfigurations?.max_discount_price ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  String(systemConfigurations.toggle)
                    .replaceAll('<', '')
                    .replaceAll('>', '')
                    .replaceAll(
                      'base discount days',
                      `<span class='toogle_light_color'>${systemConfigurations?.days_without_discount}</span>`
                    )
                    .replace(
                      'base discount',
                      `<span class='toogle_light_color'>${systemConfigurations?.discount_percentage}</span>`
                    )
                    .replace(
                      'max discount',
                      `<span class='toogle_light_color'>${systemConfigurations?.max_discount_price}</span>`
                    )
                ).replace(
                  '<tax>',
                  `<span class='toogle_light_color'>${
                    systemConfigurations?.tax * 100
                  }</span>`
                ),
              }}
            ></div>
          ) : (
            ''
          )}
        </div>
        {/* <div className='confirmation_btn'>
          <Button
            className='choose-filter-btn'
            onClick={() => setStatusFilter(!statusFilter)}
            value={statusFilter}
          >
            {!statusFilter? 'Show ': 'Hide '}Filter/Sort
          </Button>
        </div> */}
        <div className='featuredPage_sortOptions'>
          <div className='attribute_wrapper'>
            <div className='sortOptions_no_of_passenger'>
              <FormControl sx={{ m: 1, minWidth: 180 }} size='small'>
                <InputLabel id='airport_name'>Number of Passengers</InputLabel>
                <Select
                  className='airport_name'
                  labelId='no_of_passengers'
                  id='demo-simple-select'
                  value={no_of_passengers}
                  label='Number of Passengers'
                  onChange={(e) =>{
                    if(!isFetching){
                      setIsFetching(true)
                      setNo_of_passengers(e.target.value)
                    }
                  }
                  }
                  disabled={isFetching}
                >
                  <MenuItem className='airport_name_menu_item' value={'all'}>
                    All
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={2}>
                    2 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={3}>
                    3 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={4}>
                    4 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={5}>
                    5 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={6}>
                    6 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={7}>
                    7 or more
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={8}>
                    8 or more
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_size'>
              <FormControl sx={{ m: 1, width: 150 }} size='small'>
                <InputLabel id='airport_name'>Vehicle Class</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Size'
                  id='demo-simple-select'
                  value={size}
                  label='Size'
                  multiple
                  disabled={isFetching}
                  renderValue={() => 'Vehicle Class'}
                  onChange={(e) => {
                    setIsFetching(true)
                    if (!isFetching) {
                      setSize(
                        typeof e.target.value === 'string'
                          ? e.target.value.split(',')
                          : e.target.value
                      )
                    }

                  }
                  }
                >
                  {vehicleSize?.map((vSize) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={vSize}
                      sx={{
                        display: 'block !important',
                        padding: '6px !important',
                      }}
                    >
                      <Checkbox
                        checked={Boolean(size?.find((e) => e === vSize))}
                        disabled={isFetching}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                          '&.Mui-disabled': {
                            color: 'grey',
                            background: 'white-smoke',
                          },
                        }}
                      />
                      {vSize}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'none' }} className='sortOptions_model'>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='airport_name'>Fuel Type</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Model'
                  id='demo-simple-select'
                  value={fuelType}
                  label='Model'
                  disabled={isFetching}
                  onChange={(e) => {
                    setIsFetching(true)
                    if (!isFetching) {
                      setFuelType(e.target.value)
                    }
                  }
                  }
                >
                  <MenuItem className='airport_name_menu_item' value={'all'}>
                    All
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={'Hybrid'}>
                    Hybrid
                  </MenuItem>
                  <MenuItem className='airport_name_menu_item' value={'Gas'}>
                    Gas
                  </MenuItem>
                  <MenuItem
                    className='airport_name_menu_item'
                    value={'Electric'}
                  >
                    Electric
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_car_type'>
              <FormControl sx={{ m: 1, width: 150 }} size='small'>
                <InputLabel id='airport_name'>Color</InputLabel>
                <Select
                  className='airport_name'
                  labelId='Car_Color'
                  id='demo-simple-select'
                  value={car_Color}
                  label='Car Type'
                  multiple
                  disabled={isFetching}
                  renderValue={() => 'Color'}
                  onChange={(e) => {
                    if (!isFetching) {
                      setIsFetching(true)
                      setCar_Color(
                        typeof e.target.value === 'string'
                          ? e.target.value.split(',')
                          : e.target.value
                      )
                    }
                  }
                  }
                >
                  {_colors.map((_color) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={_color}
                      sx={{
                        display: 'block !important',
                        padding: '6px !important',
                      }}
                    >
                      <Checkbox
                        checked={Boolean(car_Color?.find((e) => e === _color))}
                        disabled={isFetching}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                          '&.Mui-disabled': {
                            color: 'grey',
                            background: 'white-smoke',
                          },
                        }}
                      />
                      {_color}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='sortOptions_car_type'>
              <FormControl sx={{ m: 1, width: 175 }} size='small'>
                <InputLabel id='airport_name'>Drive Train</InputLabel>
                <Select
                  className='airport_name'
                  labelId='driveTrain'
                  id='demo-simple-select'
                  value={driveTrain}
                  label='Drive Train'
                  disabled={isFetching}
                  renderValue={() => 'Drive Train'}
                  multiple
                  onChange={(e) => {
                    if (!isFetching) {
                      setIsFetching(true)
                      setDriveTrain(
                        typeof e.target.value === 'string'
                          ? e.target.value.split(',')
                          : e.target.value
                      )
                    }
                  }
                  }
                >
                  {_driveTrains?.map((_driveTrain) => (
                    <MenuItem
                      className='airport_name_menu_item'
                      value={_driveTrain}
                      sx={{
                        display: 'block !important',
                        padding: '6px !important',
                      }}
                    >
                      <Checkbox
                        checked={Boolean(
                          driveTrain?.find((e) => e === _driveTrain)
                        )}
                        disabled={isFetching}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                          '&.Mui-disabled': {
                            color: 'grey',
                            background: 'white-smoke',
                          },
                        }}
                      />
                      {_driveTrain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='sortOptions_mileage'>
            {/* Sort By */}
            <FormControl sx={{ m: 1, width: '45%' }} size='small'>
              <InputLabel id='airport_name'>Sort By</InputLabel>
              <Select
                className='airport_name'
                labelId='Select'
                id='Mileage'
                value={sortBy}
                disabled={isFetching}
                label='Mileage'
                onChange={(e) => {
                  if (!isFetching) {
                    setIsFetching(true)
                    setSortBy(e.target.value)
                  }
                }
                }
              >
                <MenuItem
                  className='airport_name_menu_item'
                  value={'-daily_price'}
                >
                  Price High to Low
                </MenuItem>
                <MenuItem
                  className='airport_name_menu_item'
                  value={'daily_price'}
                >
                  Price Low to High
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          className='go_back'
          onClick={(e) => {
            e.preventDefault();
            navigate('/');
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            viewBox='0 0 20 20'
            fill='#46a8c8'
            width={30}
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        {
          isFetching ? (
            <Modal
              open={isFetching}
              // onClose={() => setIsFetching(false)}
              aria-labelledby='conf_modal'
              aria-describedby='modal-modal-description'
            >

              <CircularProgress color='white' />
            </Modal>
          ) : (
            <>
              <FeaturedVehicles
                fromDate={props.fromDate}
                untilTime={props.untilTime}
                untilDate={props.untilDate}
                fromTime={props.fromTime}
                bookingPeriod={props.bookingPeriod}
                deliveryCharge={props.deliveryCharge}
              />
              <ChooseVehicle
                untilDate={props.untilDate}
                untilTime={props.untilTime}
                fromDate={props.fromDate}
                fromTime={props.fromTime}
                bookingPeriod={props.bookingPeriod}
                filter={getFilterVars()}
                deliveryCharge={props.deliveryCharge}
                allCars={allCars || []}
                getAllCars={getAllCars}
              />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isFetching}
          >
          <CircularProgress />
        </Backdrop>
      </>
      )

    }
    </div>
      <Footer />
    </div>
  );
}
