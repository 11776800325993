import React, { useEffect, useState } from 'react';
import CarCard from '../../CardsWrapper/CarCard';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './index.css';
import { getCarUnavailablity } from '../../../helpers/unavailablity';
import { get_all_cars } from '../../../API/index';

export default function FeaturedVehicles({
  fromDate,
  fromTime,
  untilDate,
  untilTime,
  bookingPeriod,
  deliveryCharge,
}) {
  // const FeaturedVehiclesData = [
  //   {
  //     id: 'feat_car_1',
  //     img: 'car_1',
  //     title: 'Standard SUV',
  //     similarTo: 'Ford Edge or Similar',
  //     type: 'Automatic',
  //     noOfPeople: 5,
  //     noOfBags: 5,
  //     dailyRate: 45.0,
  //     totalCost: 698.0,
  //     cardPath: '#',
  //     selectPath: '#',
  //   },
  //   {
  //     id: 'feat_car_2',
  //     img: 'car_2',
  //     title: 'Standard SUV',
  //     similarTo: 'Ford Edge or Similar',
  //     type: 'Automatic',
  //     noOfPeople: 5,
  //     noOfBags: 5,
  //     dailyRate: 45.0,
  //     totalCost: 698.0,
  //     cardPath: '#',
  //     selectPath: '#',
  //   },
  //   {
  //     id: 'feat_car_3',
  //     img: 'car_3',
  //     title: 'Intermediate',
  //     similarTo: 'Nissan Versa or Similar',
  //     type: 'Automatic',
  //     noOfPeople: 5,
  //     noOfBags: 5,
  //     dailyRate: 45.0,
  //     totalCost: 698.0,
  //     cardPath: '#',
  //     selectPath: '#',
  //   },
  // ];

  const [allCars, setAllCars] = useState(null);

  useEffect(() => {
    get_all_cars('', true).then((res) => {
      if (res.status === 200) {
        // console.log(res.data);
        setAllCars(
          res.data?.results.map((car) => ({
            ...car,
            unavailable: car.available_status !== "available",
          }))
        );
        // setAllCars([res.data[0], res.data[1], res.data[2]]);
      } else {
        setAllCars([]);
      }
    });
  }, []);

  return (
    <>
      {allCars?.length > 0 && (
        <div className='featured_vehicles'>
          <div className='heading'>Featured Vehicles</div>
          <div className='featured_card_wrapper'>
            {!allCars ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            ) : !allCars.some((e) => e !== undefined) ? (
              <div>No Featured Vehicles</div>
            ) : (
              allCars
                // .filter((e) => e.vehicle_status === 'prepped')
                .map(
                  (car, index) =>
                    car && (
                      <CarCard
                        id={car.id}
                        key={car.id}
                        img={car.car_images[0]?.image}
                        title={car.title}
                        similarTo={''}
                        type={car.type}
                        model={car.model}
                        make={car.make}
                        car={car}
                        noOfPeople={car.number_of_seats}
                        noOfBags={car.number_of_bags}
                        driveTrain={car.drive_train}
                        dailyRate={car.daily_price}
                        totalCost={car.total_price}
                        cardPath={`/car/${car.id}`}
                        selectPath={`/info/${car.id}`}
                        fromDate={fromDate}
                        fromTime={fromTime}
                        untilDate={untilDate}
                        untilTime={untilTime}
                        bookingPeriod={bookingPeriod}
                        unavailable={car.unavailable}
                        deliveryCharge={deliveryCharge}
                      />
                    )
                )
            )}
          </div>
        </div>
      )}
    </>
  );
}
